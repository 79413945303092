<template>
  <b-card>
    <b-card-header class="pb-1">
      <b-card-title>Applicants </b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <b-card-header class="pb-0">
      <h2 v-if="citizenShip === 'i'">
        17,822 </h2>
      <h2 v-else-if="citizenShip === 'oci'">
        26
      </h2>
    </b-card-header>
    <VueApexCharts
      height="350"
      type="line"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {
  BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
// import _ from 'lodash'
// import moment from 'moment'
// import json from '../../yfi-data/stage2.json'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        colors: ['#7367F0'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: [3],
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        chart: {
          id: 'Stage2 Applicant Line Chart',
          stacked: false,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },
        xaxis: {
          categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'],
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     opacityFrom: 0.8,
        //     opacityTo: 0.9,
        //   },
        // },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        yaxis: {
          min: 0,
          // max: 19000,
        },
      },
    }
  },
  mounted() {
    this.citizenShip = 'i'
  },
  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
