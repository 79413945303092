<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Work Experience</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      height="400"
      type="bar"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        colors: ['#163AB3', '#6681D9', '#AFC1FF'],
        plotOptions: {
          bar: {
            // columnWidth: '50%',
            backgroundBarRadius: 30,
            borderRadius: 30,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: '10px',
            colors: ['#FFFFFF'],
          },
        },
        chart: {
          id: 'Stage2 Work Experience',
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        yaxis: {
          min: 0,
          // max: 6000,
        },
        xaxis: {
          categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'],
        },
      },
    }
  },
  mounted() {
    this.citizenShip = 'i'
  },
  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
