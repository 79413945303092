<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="12">
        <Stage2Stage1CompareColumnChart :series="series.stage1stage2" />
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row> -->

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height"> -->
    <!-- Bar Chart - Orders -->
    <!-- <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col> -->
    <!-- Bar Chart - Orders -->
    <!-- <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col> -->

    <!-- Revenue Report Card -->
    <!-- <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col> -->
    <!-- Revenue Report Card -->
    <!-- </b-row> -->

    <b-row class="match-height">
      <b-col lg="12">
        <Stage2PrefrencesColumnChart :series="series.preference" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <Stage2AppCountLineChart :series="series.applicants" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <Stage2AcademicStreamsRadarChart :series="series.academic_streams_series" />
      </b-col>
      <b-col lg="6">
        <Stage2DemographicDonutChart :series="series.demographic_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <Stage2ApplyFellowshipRadarChart :series="series.apply_fellowship_series" />
      </b-col>
      <b-col class="match-height">
        <Stage2HowYouLearntRadarChart :series="series.how_you_learnt_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <Stage2AgeColumnChart :series="series.age_series" />
      </b-col>
      <b-col lg="6">
        <Stage2GenderLineChart :series="series.gender_series" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <Stage2WorkExperienceColumnChart :series="series.work_experience_series" />
      </b-col>
      <b-col class="match-height">
        <Stage2MaritalStatusColumnChart :series="series.marital_status" />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
// import _ from 'lodash'
// import EcommerceMedal from './EcommerceMedal.vue'
// import EcommerceStatistics from './EcommerceStatistics.vue'
// import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
// import EcommerceOrderChart from './EcommerceOrderChart.vue'
// import EcommerceProfitChart from './EcommerceProfitChart.vue'
// import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import Stage2AgeColumnChart from './Stage2AgeColumnChart.vue'
import Stage2GenderLineChart from './Stage2GenderLineChart.vue'
import Stage2DemographicDonutChart from './Stage2DemographicDonutChart.vue'
import Stage2AcademicStreamsRadarChart from './Stage2AcademicStreamsRadarChart.vue'
import Stage2ApplyFellowshipRadarChart from './Stage2ApplyFellowshipRadarChart.vue'
import Stage2HowYouLearntRadarChart from './Stage2HowYouLearntRadarChart.vue'
import Stage2WorkExperienceColumnChart from './Stage2WorkExperienceColumnChart.vue'
import Stage2MaritalStatusColumnChart from './Stage2MaritalStatusColumnChart.vue'
import Stage2AppCountLineChart from './Stage2AppCountLineChart.vue'
import Stage2PrefrencesColumnChart from './Stage2PrefrencesColumnChart.vue'
import Stage2Stage1CompareColumnChart from './Stage2Stage1CompareColumnChart.vue'
import json from '../../yfi-data/stage2Chart.json'

export default {
  components: {
    BRow,
    BCol,

    Stage2AgeColumnChart,
    Stage2GenderLineChart,
    Stage2DemographicDonutChart,
    Stage2AcademicStreamsRadarChart,
    Stage2ApplyFellowshipRadarChart,
    Stage2HowYouLearntRadarChart,
    Stage2WorkExperienceColumnChart,
    Stage2MaritalStatusColumnChart,
    Stage2AppCountLineChart,
    Stage2PrefrencesColumnChart,
    Stage2Stage1CompareColumnChart,

    // EcommerceMedal,
    // EcommerceStatistics,
    // EcommerceRevenueReport,
    // EcommerceOrderChart,
    // EcommerceProfitChart,
    // EcommerceEarningsChart,
  },
  data() {
    return {
      data: {},
      jsonData: json,
      series: {
        stage1stage2: {
          i: [
            {
              name: 'Stage1',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Stage2',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        preference: {
          i: [
            {
              name: 'A',
              data: [0, 0, 0, 0],
            },
            {
              name: 'B',
              data: [0, 0, 0, 0],
            },
            {
              name: 'C',
              data: [0, 0, 0, 0],
            },
            {
              name: 'D',
              data: [0, 0, 0, 0],
            },
            {
              name: 'E',
              data: [0, 0, 0, 0],
            },
            {
              name: 'F',
              data: [0, 0, 0, 0],
            },
            {
              name: 'G',
              data: [0, 0, 0, 0],
            },
            {
              name: 'H',
              data: [0, 0, 0, 0],
            },
            {
              name: 'I',
              data: [0, 0, 0, 0],
            },
            {
              name: 'N.A',
              data: [0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'A',
              data: [0, 0, 0, 0],
            },
            {
              name: 'B',
              data: [0, 0, 0, 0],
            },
            {
              name: 'C',
              data: [0, 0, 0, 0],
            },
            {
              name: 'D',
              data: [0, 0, 0, 0],
            },
            {
              name: 'E',
              data: [0, 0, 0, 0],
            },
            {
              name: 'F',
              data: [0, 0, 0, 0],
            },
            {
              name: 'G',
              data: [0, 0, 0, 0],
            },
            {
              name: 'H',
              data: [0, 0, 0, 0],
            },
            {
              name: 'I',
              data: [0, 0, 0, 0],
            },
            {
              name: 'N.A',
              data: [0, 0, 0, 0],
            },
          ],
        },
        applicants: {
          i: [
            {
              name: 'All Applicants',
              data: [0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'OCI Applicants',
              data: [0, 0, 0, 0, 0, 0],
            },
          ],
        },
        age_series: {
          i: [
            {
              name: '11-20',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '21-30',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '31-41',
              data: [0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '11-20',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '21-30',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '31-41',
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        gender_series: {
          i: [
            {
              name: 'M',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'F',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'GQ/NB',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'DWD',
              data: [0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'M',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'F',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'GQ/NB',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'DWD',
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        work_experience_series: {
          i: [
            {
              name: 'Less than 1',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '1-5',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'More than 5',
              data: [0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'Less than 1',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '1-5',
              data: [0, 0, 0, 0, 0],
            },
            {
              name: 'More than 5',
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        academic_streams_series: {
          i: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        apply_fellowship_series: {
          i: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: '03-Mar to 15-April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: '16-April to 03-May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        how_you_learnt_series: {
          i: [
            {
              name: 'May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
          oci: [
            {
              name: 'May',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'April',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        academic_state: {
          i: [
            {
              name: 'SSH',
              data: [0, 0, 0],
            },
            {
              name: 'Arch',
              data: [0, 0, 0],
            },
            {
              name: 'Engg',
              data: [0, 0, 0],
            },
          ],
        },
        demographic_series: {
          i: {
            labels: [],
            series: [],
          },
          oci: {
            labels: [],
            series: [],
          },
        },
        marital_status: {
          i: {
            name: ['Married', 'Single', 'Others'],
            data: [0, 0, 0, 0, 0, 0],
          },
          oci: {
            name: ['Married', 'Single', 'Others'],
            data: [0, 0, 0, 0, 0, 0],
          },
        },
      },
    }
  },
  mounted() {
    this.series.age_series = this.jsonData.age_series
    this.series.gender_series = this.jsonData.gender_series
    this.series.work_experience_series = this.jsonData.work_experience_series
    this.series.academic_streams_series = this.jsonData.academic_streams_series
    this.series.apply_fellowship_series = this.jsonData.apply_fellowship_series
    this.series.how_you_learnt_series = this.jsonData.how_you_learnt_series
    this.series.demographic_series = this.jsonData.demographic_series
    this.series.academic_state = this.jsonData.academic_state
    this.series.marital_status = this.jsonData.marital_status
    this.series.applicants = this.jsonData.applicants
    this.series.preference = this.jsonData.preference
    this.series.stage1stage2 = this.jsonData.stage1stage2
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
