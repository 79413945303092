<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Age Group</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      height="400"
      type="bar"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

// colors
const chartColors = {
  column: {
    series1: '#FF8F1C',
    series2: '#FFAC58',
    series3: '#FFD8B0',
    bg: '#EEEEEE',
  },
}

export default {
  components: {
    VueApexCharts,
    // BDropdown,
    // BDropdownItem,
    // BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    // BCardBody,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        chart: {
          id: 'Stage2 Age Column Chart',
          stacked: true,
          toolbar: {
            show: true,
          },
        },
        colors: [chartColors.column.series1, chartColors.column.series2, chartColors.column.series3],
        plotOptions: {
          bar: {
            // columnWidth: '50%',
            borderRadius: 30,
            backgroundBarRadius: 30,
            horizontal: false,
            dataLabels: {
              position: 'center',
              colors: '#FFFFFF',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: '10px',
            colors: ['#FFFFFF'],
          },
        },
        stroke: {
          show: false,
          width: 1,
          colors: ['#EEE'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'],
        },
        yaxis: {
          min: 0,
          // max: 6000,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          horizontalAlign: 'left',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    }
  },
  mounted() {
    this.citizenShip = 'i'
  },
  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
