<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Preference</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      height="400"
      type="line"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        chart: {
          id: 'Stage2 Prefernce Column Chart',
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 3,
        },
        stroke: {
          curve: 'smooth',
          width: [3, 3, 3, 3],
        },
        colors: ['#1390FB', '#37E396', '#FCB01A', '#F94460', '#775DD0', '#FBE813', '#B15DD0', '#8CE337', '#F944BB', '#C1D05D'],
        // plotOptions: {
        //   bar: {
        //     // columnWidth: '30%',
        //     backgroundBarRadius: 10,
        //     borderRadius: 10,
        //   },
        // },
        xaxis: {
          categories: ['1st Preference', '2nd Preference', '3rd Preference', '4th Preference'],
        },
        yaxis: {
          min: 0,
          // max: 18000,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          horizontalAlign: 'left',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    }
  },
  mounted() {
    this.citizenShip = 'i'
  },
  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
