<template>
  <b-card>
    <b-card-header class="pb-10">
      <b-card-title>Stage1 Stage2 Compare</b-card-title>
      <b-form-checkbox
        checked="false"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
        @change="toggleCitizenShip"
      >
        <p v-if="citizenShip === 'i'">
          All </p>
        <p v-else-if="citizenShip === 'oci'">
          OCI
        </p>
      </b-form-checkbox>
    </b-card-header>
    <VueApexCharts
      height="400"
      type="bar"
      :options="options"
      :series="series[citizenShip]"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    series: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citizenShip: null,
      options: {
        chart: {
          id: 'Stage1 Stage2 Compare Column Chart',
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        // colors: [chartColors.column.series1, chartColors.column.series2, chartColors.column.series3],
        plotOptions: {
          bar: {
            // columnWidth: '30%',
            backgroundBarRadius: 10,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: ['03/03 to 09/03', '10/03 to 16/03', '17/03 to 23/03', '24/03 to 30/03', '31/03 to 06/04', '07/04 to 13/04', '14/04 to 20/04', '21/04 to 27/04', '28/04 to 03/05'],
          // categories: ['One', 'Two', 'Three'],
        },
        yaxis: {
          min: 0,
          // max: 18000,
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    }
  },
  mounted() {
    this.citizenShip = 'i'
  },
  methods: {
    toggleCitizenShip() {
      this.citizenShip = (this.citizenShip === 'oci') ? 'i' : 'oci'
    },
  },
}
</script>
